import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Footer = () => {
    return (
        <footer className="secondary">
            <div style={{ display: "grid", justifyContent: "center", gridTemplateColumns:"100%" }}>

                <span>
                    <Popup
                        trigger={<button className="footer-button primary" style={{ color: "inherit", marginBottom: "5px" }}>Contact</button>}
                        position="top center"
                        nested
                    >
                        <span style={{ textAlign: "center", width: "100%", display: "block" }}>kdjstuffs@gmail.com</span>
                    </Popup>
                </span>


                <div className="light" style={{ margin: "0px" }}>
                    <div>
                        Developed by KDJ
                    </div>
                    <a href="https://devforum.roblox.com/t/1652805" target="_blank" className="link-button"><div style={{display:"inline-block"}} className="link-button">
                        Roblox Developer Forum Post
                    </div></a>
                    <div>
                        RobloxNames.com is not endorsed, sponsored or affiliated with Roblox Corporation
                    </div>
                    <div>
                        Copyright © RobloxNames.com {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;