import { useState } from 'react';
import { Link } from 'react-router-dom';

const HowDoesThisWork = () => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div>
            <h2>How does this work?</h2>

            <p>
                This page works together with our <Link to="/live" className="link-button">Live Roblox User Stream</Link> to give you a visualization of the 200 most popular terms that have appeared in Roblox usernames over the past day.
            </p>
            {showMore && (
                <>
                    <h4>Technical Explanation<span style={{ fontSize: "11px" }}> (for my fellow tech geeks)</span></h4>
                    <p>
                        Our servers process the data that is collected from our <Link to="/live" className="link-button">Live Roblox User Stream</Link> to check for defined terms. 
                        What our system considers a term is based off of data from <a href="https://github.com/dwyl/english-words" className="link-button">this GitHub repository</a>. 
                        Some terms that are relevant to Roblox (roblox, noob, robloxian) that do not appear normally in the English dictionary have also been added manually. 

                    </p>
                    <p>
                        Daily at 12 midnight UTC the data collected from the previous day is erased to begin recording data for new day.
                    </p>
                </>
            )}
            <button className="link-button" onClick={() => setShowMore(!showMore)}>
                {showMore ? "Hide technical explanation" : "Show technical explanation"}
            </button>
        </div >
    );
}

export default HowDoesThisWork;