import "./App.css";
import SearchModal from "./SearchModal";
import Pause from "./Pause";
import HowDoesThisWorkLive from "./HowDoesThisWorkLive";
import { useState, useEffect, useRef } from "react"
import CountUp from "react-countup";
import Back from "./Back";
import { Helmet } from "react-helmet";


const URL = "wss://www.robloxnames.com:8080/";
const rowWidth = 196;
const maximumLoadAmount = 35;

let terms = JSON.parse(localStorage.getItem("terms"));
if (!terms) {
  terms = [];
}
function Live() {
  String.prototype.commafy = function () {
    return this.replace(/(^|[^\w.])(\d{4,})/g, function ($0, $1, $2) {
      return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, "$&,");
    });
  };
  const [count, setCount] = useState(null);
  const [hide, setHide] = useState("hide");
  const [loading, setLoading] = useState(true);
  const [termsState, setTermsState] = useState(terms);
  const [popularTerms, setPopularTerms] = useState([]);
  const [paused, setPaused] = useState(false);
  const [usersPerSecond, setUsersPerSecond] = useState(null);
  global.termsStateVariable = termsState;
  global.pausedVariable = paused;

  const isMounted = useRef(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    global.pausedVariable = paused;
    setUsersPerSecond(null);
  }, [paused])
  useEffect(() => {
    global.termsStateVariable = termsState;

    let users = document.getElementsByClassName("userText");
    while (document.getElementsByTagName("mark").length !== 0) {
      let marks = document.getElementsByTagName("mark");
      for (var i = 0; i < marks.length; i++) {
        const element = marks[i];
        while (element.firstChild) {
          element.parentNode.insertBefore(element.firstChild,
            element);
        }
        element.parentNode.removeChild(element);
      }
    }

    for (const user of users) {
      let terms = [];
      global.termsStateVariable.forEach((term) => {
        if (term.term !== "") {
          terms.push(term.term);
        }
      });

      let text = user.innerHTML;
      const regex = new RegExp(terms.join("|"), "gi");
      text = text.replace(regex, `<mark class="highlight">$&</mark>`);

      user.innerHTML = text;

      let marks = user.getElementsByTagName("mark");
      for (const mark of marks) {
        global.termsStateVariable.forEach((term) => {
          if (term.term !== "") {
            if (mark.innerHTML.toLowerCase() === term.term.toLowerCase()) {
              mark.style.setProperty('background-color', term.color, 'important');
            }
          }
        })
      }
    }

  }, [termsState])
  function getRowNum() {
    return ((expanded.current && Math.floor((window.innerWidth) / (rowWidth))) || 1);
  }
  function scoochClass() {
    if (Math.floor((window.innerWidth) / (rowWidth)) <= 2) {
      return " scooch";
    } else {
      return "";
    }
  }

  useEffect(() => {
    const ws = new WebSocket(URL);
    let lastMessageRecieved = null;
    let lastAmount = null;
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      const newCreated = data.mostRecentId - lastAmount;
      lastAmount = data.mostRecentId;
      if (lastMessageRecieved !== null && global.pausedVariable === false) {
        const timeSinceLastMessage = Date.now() - lastMessageRecieved;
        setUsersPerSecond(Math.floor((newCreated / (timeSinceLastMessage / 1000))));
      }
      lastMessageRecieved = Date.now();
      if (!document.hidden && data.errorCode !== 1) {
        setLoading(false);
        let newMostRecentUsers = data.mostRecentUsers;
        let index = newMostRecentUsers.length - newCreated;
        if (index < 0) {
          index = 0;
        }

        newMostRecentUsers = newMostRecentUsers.slice(index, newMostRecentUsers.length);
        let x = 0;
        let timeLeft = 5100;
        function addToRows() {
          x += getRowNum();
          const permX = x;
          const rowNum = getRowNum();
          for (let n = 0; n < rowNum; n++) {
            setTimeout(() => {
              if (!global.pausedVariable) {
                if (isMounted.current) {
                  if (newMostRecentUsers[permX + n]) {
                    setCount(newMostRecentUsers[permX + n].id);
                    let text = newMostRecentUsers[permX + n].name;

                    let terms = [];
                    global.termsStateVariable.forEach((term) => {
                      if (term.term !== "") {
                        terms.push(term.term);
                      }
                    });
                    const regex = new RegExp(terms.join("|"), "gi");
                    text = text.replace(regex, `<mark class="highlight">$&</mark>`);

                    const newElem = `<a class="elem user" href="https://www.roblox.com/users/${newMostRecentUsers[permX + n].id}/profile" target="_blank"><span class="userText" style="position:relative;z-index:2;">${text}</span></a>`;
                    const row = document.getElementById("App-rows").children[n]
                    if (row !== undefined) { //make sure row still exists since the setTimeout causes delay
                      row.insertAdjacentHTML("afterbegin", newElem);
                      let marks = row.getElementsByClassName("elem")[0].getElementsByTagName("mark");

                      global.termsStateVariable.forEach((term) => {
                        for (const mark of marks) {
                          if (mark.innerHTML.toLowerCase() === term.term.toLowerCase()) {
                            mark.style.setProperty('background-color', term.color, 'important');
                          }
                        }
                      })

                      if (row.children.length > maximumLoadAmount) {
                        row.children[row.children.length - 1].remove();
                      }
                    }
                  }
                }
              }
            }, (Math.random() - 0.5) * 2 * getRowNum() * 37.5);
          }

          setTimeout(() => {
            setHide("");
          }, 150);
          setTimeout(() => {
            if (x < newMostRecentUsers.length) {
              timeLeft -= timeLeft / ((newMostRecentUsers.length - x) / getRowNum());
              addToRows();
            }
          }, timeLeft / ((newMostRecentUsers.length - x) / getRowNum()));
        }
        addToRows();

      } else {
        if (global.pausedVariable === false) {
          setLoading(true);
          lastMessageRecieved = null;
          setUsersPerSecond(null);
        }
      }
    }

    return () => {
      ws.close();
    }
  }, []);

  useEffect(async () => {
    /* const res = await fetch("https://www.robloxnames.com:3000/popularTerms?n=10&minChar=5");
    const popularTermsData = await res.json()
    let popularTerms = [];
    popularTermsData.forEach((termData) => {
      popularTerms.push(termData.term);
    }); */
    setPopularTerms(["noob", "dog", "kid", "roblox", "love", "gamer"]);
  }, []);

  const [rowNum, setRowNum] = useState(Math.floor((window.innerWidth) / rowWidth));
  window.onresize = () => setRowNum(Math.floor((window.innerWidth) / rowWidth));

  function getCurrentCount() {
    return Number((document.getElementById("App-count").getElementsByTagName("span")[0] && document.getElementById("App-count").getElementsByTagName("span")[0].innerHTML.replace(/,/g, "")) || count - 300);
  }

  let expanded = useRef(true);
  function onExpandButtonClick() {
    expanded.current = !expanded.current;
    if (expanded.current === false) {
      const expandAndCollapseButton = document.getElementById("expand-and-collapse");
      expandAndCollapseButton.classList.add("rotate");
      expandAndCollapseButton.children[0].classList.add("hide");
      Array.from(document.getElementsByClassName("App-row-div")).forEach((element, index) => {
        if (index !== 0) { //is not the first row
          element.classList.add("minimize");
        }
      });
    } else {
      const expandAndCollapseButton = document.getElementById("expand-and-collapse");
      expandAndCollapseButton.classList.remove("rotate");
      expandAndCollapseButton.children[0].classList.remove("hide");

      function isMobile() { return ('ontouchstart' in document.documentElement); }
      if (isMobile()) {
        const expandAndCollapseButtonCircle = document.getElementById("expand-and-collapse-circle");
        expandAndCollapseButtonCircle.parentElement.classList.remove("tempRotate");
        expandAndCollapseButtonCircle.parentElement.children[0].classList.remove("tempHide");
      }

      Array.from(document.getElementsByClassName("App-row-div")).forEach((element, index) => {
        if (index !== 0) { //is not the first row
          element.classList.remove("minimize");
        }
      });
    }
  }
  const expandAndCollapseButton = document.getElementById("expand-and-collapse-circle");
  if (expandAndCollapseButton) {
    expandAndCollapseButton.onmouseover = function () {
      expandAndCollapseButton.parentElement.classList.add("tempRotate");
      expandAndCollapseButton.parentElement.children[0].classList.add("tempHide");
    }
    expandAndCollapseButton.onmouseout = function () {
      expandAndCollapseButton.parentElement.classList.remove("tempRotate");
      expandAndCollapseButton.parentElement.children[0].classList.remove("tempHide");
    }
  }

  return (
    <div className="App secondary">

      <Helmet>
        <title>RobloxNames - Live</title>
        <meta name="keywords" content="Roblox, Names, Stream, Usernames, Live" />
        <meta name="description" content="Displays live Roblox username data for users being created in real time" />
      </Helmet>
{/*       <Back /> */}
      <header className="App-header secondary" style={{ position: "relative" }}>
        <p style={{ fontSize: "1em", marginBlockStart: "0.2em" }}>
          Roblox users created
        </p>
        <span id="App-count" className={hide}>{count !== null && count !== 0 && (
          <CountUp
            start={getCurrentCount()}
            end={count}
            duration={0.5}
            separator=","
          >
          </CountUp>
        )}

          <span id="usersPerSecond" className={usersPerSecond === null ? "hide" : ""} style={{ fontSize: "0.5em", paddingLeft: "10px", marginRight: "-0.5em" }}>{usersPerSecond}/s</span>

        </span>
      </header>
      <div className="primary" style={{ paddingBottom: "20px" }}>
        <h1 style={{ textAlign: "center", marginBlockStart: "0em", paddingTop: "0.3em" }} className="primary">Live Roblox Username Stream</h1>
        <section className="primary">
          <div id="list-header" className="primary">
            <SearchModal popularTerms={popularTerms} terms={termsState} setTerms={setTermsState} /><Pause paused={paused} setPaused={setPaused} />
            {loading && (
              <div style={{ backgroundColor: "transparent" }}><div className={"loader" + scoochClass()} style={{ backgroundColor: "transparent" }}></div><div style={{ backgroundColor: "transparent" }} className="loader-text" style={{ backgroundColor: "transparent" }}>Syncing with server...</div></div>
            )}
            {(!loading && Math.floor((window.innerWidth) / (rowWidth)) > 1) && (
              <button id="expand-and-collapse" className={"button secondary" + scoochClass()} onClick={onExpandButtonClick}><span style={{ margin: "6px", transition: "opacity 0.1s" }}>❮</span><span style={{ margin: "6px" }}>❯</span><div id="expand-and-collapse-circle"></div></button>
            )}
          </div>

          {paused && <p style={{ color: "#dc3545", textAlign: "center", backgroundColor: "transparent" }}>Stream is currently paused.</p>}

          <div id="App-rows" className={"primary " + hide}>
            {Array.from({ length: rowNum }, (item, index) => (
              <div className="App-row-div primary" key={index}>

              </div>
            ))}
          </div>
        </section>
        <section id="explanatory-content" className="primary">
          <HowDoesThisWorkLive />
        </section>

      </div>
    </div>
  );
}

export default Live;
