import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { BsInfoCircleFill } from 'react-icons/bs';

const Info = ({text, size, popupPosition}) => {
    return (
        <Popup
            trigger={<button style={{ visibility: "hidden", padding: "0px", color: "inherit" }}><BsInfoCircleFill style={{ visibility: "visible", cursor: "pointer", width:size, height:size}} /></button>}
            position={popupPosition}
            nested
            className='fixed'
            contentStyle={{width:"100px !important"}}
        >
            <span>
                {text}
            </span>
        </Popup>
    );
}

export default Info;