import "./App.css";
import { useEffect, useState } from "react";
import Back from "./Back";
import HowDoesThisWorkSearch from "./HowDoesThisWorkSearch";
import { Helmet } from "react-helmet";
import { BiSearch } from 'react-icons/bi';

String.prototype.commafy = function () {
    return this.replace(/(^|[^\w.])(\d{4,})/g, function ($0, $1, $2) {
        return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, "$&,");
    });
};
let lastFetch = null;
let lastTermFetched = null;
let fetchIndex = 0;
const Search = () => {
    let [searchTerm, setSearchTerm] = useState("");
    let [searchTermData, setSearchTermData] = useState([])
    let [emptySearch, setEmptySearch] = useState(false);
    let [waitToFetch, setWaitToFetch] = useState(false);
    let [checkboxesState, setCheckboxesState] = useState([true, false]);
    let [canLoadMore, setCanLoadMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [hide, setHide] = useState("hide");

    function getTNumber() {
        if (document.getElementById("names-checkbox")) {
            const namesChecked = document.getElementById("names-checkbox").checked;
            const displayChecked = document.getElementById("display-names-checkbox").checked;

            if (namesChecked && displayChecked) {
                return 2;
            }
            if (namesChecked) {
                return 0;
            }
            if (displayChecked) {
                return 1;
            }
        } else {
            return null;
        }
    }

    async function submit() {
        const term = document.getElementById('search-form').getElementsByTagName("input")[0].value;
        document.getElementById('search-form').getElementsByTagName("input")[0].blur();

        if (lastTermFetched !== term || (Date.now() - lastFetch >= 5000 || lastFetch === null)) {
            if (getTNumber() !== undefined) {
                setWaitToFetch(false);
                if (term !== "") {
                    setLoading(true);
                    lastFetch = Date.now();
                    lastTermFetched = term;
                    let res = await fetch(`https://www.robloxnames.com:3000/names/${term}?n=200&t=${getTNumber()}&i=${0}`);
                    let data = await res.json();

                    setCanLoadMore(true);
                    if (data.length < 200) {
                        setCanLoadMore(false);
                    }

                    setSearchTermData(data);

                    fetchIndex = 200;
                    

                    setLoading(false);
                    setHide("");
                } else {
                    setEmptySearch(true);
                }
            }
        } else {
            setWaitToFetch(true);
        }
    };
    useEffect(() => {
        document.getElementById('search-form').addEventListener('submit', function (e) {
            e.preventDefault(); //to prevent form submission
            submit();
        });
        document.getElementById("names-checkbox").checked = true;
    }, [])

    useEffect(() => {
        const term = document.getElementById('search-form').getElementsByTagName("input")[0].value;
        const users = document.getElementsByClassName('name-data');
        for (const user of users) {
            if (user.getElementsByClassName("highlight")[0] === undefined) {
                let text = user.innerHTML;
                const regex = new RegExp(term, "gi");
                text = text.replace(regex, `<mark class="highlight">$&</mark>`);
                user.innerHTML = text;
            }
        }
    }, [searchTermData, checkboxesState])

    function setTerm() {
        setEmptySearch(false);
        const value = document.getElementById('search-form').getElementsByTagName("input")[0].value;
        if (/^[a-zA-Z0-9\_]+$/g.test(value) || value === "") {
            setSearchTerm(value);
        }
    }

    function checkboxChanged() {
        setCheckboxesState([document.getElementById("names-checkbox").checked, document.getElementById("display-names-checkbox").checked]);
    }

    async function loadMore() {
        const term = document.getElementById('search-form').getElementsByTagName("input")[0].value;
        let res = await fetch(`https://www.robloxnames.com:3000/names/${term}?n=200&t=${getTNumber()}&i=${fetchIndex}`);
        let data = await res.json();
        if (data.length < 200) {
            setCanLoadMore(false);
        }
        setSearchTermData(searchTermData.concat(data));
        fetchIndex += 200;
    }
    return (
        <div className="App">
            <Helmet>
                <title>RobloxNames - Search</title>
                <meta name="keywords" content="Roblox, Names, Display Names, Usernames, Search, Recent" />
                <meta name="description" content="Search for the most recent usernames and display names from the past week containing specified terms." />
            </Helmet>
            <Back />
            <header className="App-header secondary">
                <h1 style={{ textAlign: "center", marginBlockStart: "0em" }} className="secondary">Term Search</h1>

                <div id="checkboxes">
                    <label>Search Usernames<input type="checkbox" id="names-checkbox" name="names-checkbox" onClick={checkboxChanged} /></label>
                    <label>Search Display Names<input type="checkbox" id="display-names-checkbox" name="display-names-checkbox" onClick={checkboxChanged} /></label>
                </div>

                <div className="search-container">
                    <form id="search-form">
                        <input className="primary" type="text" value={searchTerm} onChange={setTerm} onKeyDown={(e) => e.keyCode === 13 && submit()} placeholder="Search.." name="search" />
                        <button className="primary" type="submit"><BiSearch /></button>
                    </form>
                </div>
            </header >
            <div style={{ textAlign: "center" }}>
                {
                    emptySearch && (
                        <p style={{ color: "#dc3545", fontSize: "15px" }}>Please enter a term to search for</p>
                    )
                }

                {
                    (checkboxesState[0] === false && checkboxesState[1] === false) && (
                        <p style={{ color: "#dc3545", fontSize: "15px" }}>Please check at least one search box.</p>
                    )
                }
                {
                    waitToFetch && (
                        <p style={{ color: "#dc3545", fontSize: "15px" }}>Please wait 5 seconds before performing another search for the same term.</p>
                    )
                }
                {
                    searchTermData[0] === undefined && fetchIndex > 0 && (
                        <p style={{ color: "#dc3545", fontSize: "15px" }}>No names containing this term found.</p>
                    )
                }
            </div>

            {loading && (
                <div><div className="loader" ref={(el) => el && el.style.setProperty("margin-top", "0px", "important")}></div><div style={{ backgroundColor:"transparent" }} className="loader-text">Syncing with server...</div></div>
            )}

            <div style={{ padding: "20px", transition: "opacity 0.5s", opacity: 1 }} className={hide}>
                {searchTermData[0] !== undefined && (
                    <>

                        <table>
                            <tbody>
                                <tr>
                                    <th>#</th>
                                    <th>nth created</th>
                                    {checkboxesState[0] === true && (
                                        <th>Name</th>
                                    )}
                                    {checkboxesState[1] === true && (
                                        <th>Display Name</th>
                                    )}
                                </tr>
                                {searchTermData.map((data, index) => (
                                    <tr key={index}>
                                        <a href={`https://www.roblox.com/users/${data[2]}/profile`} target="_blank" style={{ display: "contents" }}>
                                            <td>{index + 1}.</td>
                                            <td>{String(data[2]).commafy()}</td>
                                            {checkboxesState[0] === true && (
                                                <td className="name-data">{data[0]}</td>
                                            )}
                                            {checkboxesState[1] === true && (
                                                <td className="name-data">{data[1]}</td>
                                            )}
                                        </a>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ textAlign: "center" }}>
                            {canLoadMore && (
                                <button className="link-button" style={{ fontSize: "20px", marginBottom: "10px", textAlign: "center" }} onClick={loadMore}>Load more...</button>
                            )}
                        </div>
                    </>
                )}
            </div>

            {searchTermData[0] !== undefined && (
                <a className="link-button" href="#top" style={{ textAlign: "center" }}><h3>To top ^</h3></a>
            )}

            <section id="explanatory-content">
                <HowDoesThisWorkSearch />
            </section>
        </div >
    );
}

export default Search;