import { useState, useEffect } from "react"
import Info from './Info';

const ResetCountdown = () => {
    const [timeTillReset, setTimeTillReset] = useState(null);

    useEffect(() => {
        function setTime() {
            function formatMs(ms) {
                let h = ms / 3.6e6 | 0;
                let m = (ms % 3.6e6) / 6e4 | 0;
                let s = (ms % 6e4) / 1e3 | 0;
                return `${h} hours ${m} minutes and ${s} seconds until reset`;
            }

            function timeToUTCMidnight(d = new Date()) {
                return formatMs(new Date(d).setUTCHours(24, 0, 0, 0) - d);
            }
            setTimeTillReset(timeToUTCMidnight());
        }
        setTime();
        setInterval(setTime, 1000);
    }, [])


    return (
            <span id="reset-countdown" style={{ margin: "-40px auto 0 auto", display: "table" }}>
                <h3 style={{ display: "inline-block", marginRight: "5px" }}>{timeTillReset}<Info popupPosition="top center" size="20px" text="Every day at 12 midnight UTC all counts are reset to allow for a fresh analysis each day." /></h3>
            </span>
    );
}

export default ResetCountdown;