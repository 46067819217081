import { Routes, Route } from 'react-router-dom';
import Live from './Live';
import Popular from './Popular';
import Search from './Search';
import Rickroll from './Rickroll';
import Home from './Home';
import Footer from './Footer';
import DarkMode from './DarkMode';

const App = () => {
    return (
        <>
            <DarkMode />
            <Routes>
                <Route path="/live" element={<Live />} exact />
                <Route path="/popular" element={<Popular />} exact />
                <Route path="/search" element={<Search />} exact />
                {/* <Route path="/livestream" element={<Rickroll />} exact /> */}
                <Route path="*" element={<Live />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;