import { BsFillPauseFill } from 'react-icons/bs';
import { BsPlayFill } from 'react-icons/bs';

const Pause = ({paused, setPaused}) => {
    return ( 
        <button className="button pause-button" onClick={() => setPaused(!paused)}>
            {paused ? (
                <BsPlayFill size="50px" color="#555" style={{margin:"-25px"}}/>
            ) : (
                <BsFillPauseFill size="50px" color="#555" style={{margin:"-25px"}}/>
            )}
        </button>
     );
}
 
export default Pause;