import { useState } from 'react';
import { Link } from 'react-router-dom';

const HowDoesThisWork = () => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div>
            <h2>How does this work?</h2>

            <p>
                This page works together with our <Link to="/live" className="link-button">Live Roblox User Stream</Link> to allow you to search for the most recent usernames and display names from the past week containing specified terms.
            </p>
            {showMore && (
                <>
                    <h4>Technical Explanation<span style={{ fontSize: "11px" }}> (for my fellow tech geeks)</span></h4>
                    <p>
                        Our servers store usernames processed through our <Link to="/live" className="link-button">Live Roblox User Stream</Link> to our database. When you make a term search, a case-insensitive query of our database is made for your term, and results are returned to you.
                        

                    </p>
                    <p>
                        Every new day UTC all stored names and display names are erased to begin recording again.
                    </p>
                </>
            )}
            <button className="link-button" onClick={() => setShowMore(!showMore)}>
                {showMore ? "Hide technical explanation" : "Show technical explanation"}
            </button>
        </div >
    );
}

export default HowDoesThisWork;