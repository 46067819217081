import useDarkMode from 'use-dark-mode';
import DarkModeToggle from "react-dark-mode-toggle";

const DarkMode = () => {
    const darkMode = useDarkMode(false);
    return (
        <span style={{ position: "absolute", right: "0px", padding: "10px", zIndex:10, backgroundColor:"transparent" }}>
            <DarkModeToggle
                onChange={darkMode.toggle}
                checked={darkMode.value}
                size={80}
            />
        </span>
    );
}

export default DarkMode;