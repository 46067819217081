import { useEffect } from 'react';
import Popup from 'reactjs-popup';
import Info from './Info';
import 'reactjs-popup/dist/index.css';

import { BiSearchAlt2 } from 'react-icons/bi';

const defaultColors = ["#ff0000", "#ffa500", "#ff80ed", "#009B77", "#DFCFBE"];
const FilterModal = ({ popularTerms, terms, setTerms }) => {
    function createTerm(term) {
        let newColor;
        for (let i = 0; i < defaultColors.length; i++) {
            const color = defaultColors[i];
            let colorUsed = false;
            terms.forEach((thisTerm, index) => {
                if (thisTerm.color === color) {
                    colorUsed = true;
                }
            })
            if (!colorUsed) {
                newColor = color;
                break;
            }
        }
        setTerms(terms => [...terms, { term: term || "", color: newColor }]);
    }
    function removeTerm(index) {
        setTerms(terms.filter((_, i) => i !== index));
    }
    function setTerm(index) {
        let newTerms = [...terms];
        const value = document.getElementsByClassName("terms")[0].getElementsByTagName("span")[index].children[0].value;
        if (/^[a-zA-Z0-9\_]+$/g.test(value) || value === "") {
            newTerms[index].term = document.getElementsByClassName("terms")[0].getElementsByTagName("span")[index].children[0].value;
            setTerms(newTerms);
        }
    }
    function setTermColor(index) {
        let newTerms = [...terms];
        newTerms[index].color = document.getElementsByClassName("terms")[0].getElementsByTagName("span")[index].children[1].value;

        setTerms(newTerms);
    }
    function isThereDuplicateTerms() {
        let isDuplicate = false;
        terms.map((term, index) => {
            terms.map((thisTerm, thisIndex) => {
                if (index !== thisIndex) {
                    if (term.term === thisTerm.term) {
                        isDuplicate = true;
                    }
                }
            })
        })
        return isDuplicate;
    }
    function isThereBlankTerms() {
        let isThereBlank = false;
        terms.map((term) => {
            if (term.term === "") {
                isThereBlank = true;
            }
        })
        return isThereBlank;
    }
    localStorage.setItem("terms", JSON.stringify(terms));

    useEffect(() => {
        const termsElement = document.getElementsByClassName("terms")[0];
        if (termsElement && termsElement.getElementsByTagName("span") && termsElement.getElementsByTagName("span")[termsElement.getElementsByTagName("span").length - 1]) {
            const lastElement = termsElement.getElementsByTagName("span")[termsElement.getElementsByTagName("span").length - 1].children[0];
            if (lastElement.value === "") {
                lastElement.focus();
            }
        }
    });
    return (
        <Popup
            trigger={<button className="button modal-button secondary">Term Search <BiSearchAlt2 style={{ marginLeft: "10px" }} /></button>}
            modal
            nested
            className='fixed'
            contentStyle={{width:"300px !important"}}
        >
            {close => (
                <div className="modal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header">Edit Term Search <Info popupPosition="bottom center" text="Choose terms to be highlighted by a specific color in the stream of usernames."/></div>


                    <div className="terms">
                        {terms.length < 5 ? <button className="add-term rotate45" onClick={() => createTerm()}>×</button>
                            :
                            <Popup
                                trigger={<button className="add-term rotate45">×</button>}
                                position="top left"
                                nested
                            >
                                <span>
                                    You cannot create more than 5 terms at a time.
                                </span>
                            </Popup>
                        }


                        {terms.map((term, index) => (
                            <span className="elem term secondary" key={index}>
                                <input className="term-text" type="text" maxLength="20" value={term.term} placeholder="a term, e.g. noob" onChange={() => setTerm(index)}></input><input type="color" className="color-input" value={term.color} onChange={() => setTermColor(index)}></input><button className="remove-term" onClick={() => removeTerm(index)}>×</button>
                            </span>
                        ))}
                    </div>

                    {isThereBlankTerms() ? <p style={{ color: "#dc3545" }}>You have a blank term. Please either remove it or assign a term to search for.</p>
                        : isThereDuplicateTerms() && <p style={{ color: "#dc3545" }}>You have two or more duplicate terms. Only the last term color listed will take effect.</p>}

                    <div className="popular-terms">
                        <h5>Popular Username Terms <Info popupPosition="top center" text={`These are some of the most common terms that appear in usernames.`}/></h5>
                        <div style={{ marginLeft: "20px" }}>
                            {popularTerms.map((term, index) => {
                                if (terms.length < 5) {
                                    return <button key={index} className="small-button secondary" onClick={() => createTerm(term)}>{term}</button>;
                                } else {
                                    return (
                                        <Popup
                                            trigger={<button key={index} className="small-button secondary">{term}</button>}
                                            position="top center"
                                            nested
                                            key={index}
                                        >
                                            <span>
                                                You cannot create more than 5 terms at a time.
                                            </span>
                                        </Popup>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    )
}

export default FilterModal;