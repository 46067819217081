import { useState } from 'react';
import { Link } from 'react-router-dom';

const HowDoesThisWork = () => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div className="primary">
            <h2 className="primary">How does this work?</h2>

            <p className="primary">
                Our servers are constantly monitoring and processing created users on roblox.com to give you a Live Roblox User Count and Username Stream, with an average delay of less than 5 seconds.
            </p>
            {showMore && (
                <>
                    <h4 className="primary">Technical Explanation<span className="primary" style={{ fontSize: "11px" }}> (for my fellow tech geeks)</span></h4>
                    <p className="primary">
                        The backend for the Live User Count and Live Username Stream on this page are handled in two seperate modules.
                    </p>
                    <div className="primary">
                        <h5 className="primary">Live User Count</h5>
                        The Live User Count stays up to date by using an algorithm that makes requests at a fast interval to check if a user of a certain ID exists or not. The basic idea behind this algorithm consists of two possiblities:
                        <ol className="primary">
                            <li className="primary">The first is that a request is made and then returns that a user doesn't exist yet, and in this case the mostRecentUserId will not be updated. </li>
                            <li className="primary">The second is that the user does exist by the ID that we requested, and in this case the mostRecentUserId will be incremented.</li>
                        </ol>
                        <p className="primary">
                            After either of these possiblities, a request is made again to check for the existence of a user by the mostRecentUserId, and the algorithm continues. This is the logic loop behind this module.
                        </p>
                    </div>
                    
                    <div className="primary">
                        <h5 className="primary">Live Username Stream</h5>
                        The Live Username Stream works by periodically making requests to Roblox's APIs to get username data for newly created users. 
                        Input from the Live User Count module is used to determine the range of new user IDs. 
                        Proxies are also used to help avoid rate limits. 
                        Once data is returned from each request, it is processed and saved to our database for future use by <Link to="/" className="link-button">our other services</Link>, as well as streamed to all instances of this page through web sockets to be rendered.
                    </div>

                    <p className="primary">Thank you for reading this technical explanation. Hopefully you have enjoyed being enlightened to the inner workings of this service.</p>
                </>
            )}
            <button className="link-button" onClick={() => setShowMore(!showMore)}>
                {showMore ? "Hide technical explanation" : "Show technical explanation"}
            </button>
        </div >
    );
}

export default HowDoesThisWork;