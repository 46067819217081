import "./App.css";
import { useState, useEffect } from "react"
import Info from './Info';
import HowDoesThisWorkPopular from "./HowDoesThisWorkPopular";
import Back from "./Back";
import ResetCountdown from "./ResetCountdown";
import { Helmet } from "react-helmet";

const Popular = () => {
    useEffect(() => {
        document.title = 'RobloxNames - Popular';
    })

    const [popularTermsData, setPopularTermsData] = useState([]);
    const [minChar, setMinChar] = useState(6);
    const [previousMinChar, setPreviousMinChar] = useState();
    const [minCharError, setMinCharError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hide, setHide] = useState("hide");

    function setMinCharFunc(val) {
        if ((val > 0 && val <= 10)) {
            setMinCharError(false);
            setMinChar(val);
        } else {
            setMinCharError(true);
        }
        if (isNaN(val)) {
            setMinCharError(false);
            setMinChar("");
        }
    }

    async function refresh() {
        if (minChar !== "" && minChar !== previousMinChar) {
            setPreviousMinChar(minChar);
            setLoading(true);
            const res = await fetch(`https://www.robloxnames.com:3000/popularTerms?n=200&minChar=${minChar}`);
            const data = await res.json();
            setPopularTermsData(data);
            setLoading(false);
            setHide("");
        }
    }

    useEffect(() => {
        refresh();
        document.getElementById('minCharInput').addEventListener('keydown', function (e) {
            if (e.which === 13) this.blur();
        });
    }, [])
    return (
        <div className="App" style={{paddingBottom:"120px"}}>
            <Helmet>
                <title>RobloxNames - Popular</title>
                <meta name="keywords" content="Roblox, Names, Usernames, Popular, Common" />
                <meta name="description" content="Provides tables that show most popular Roblox username terms from the past day" />
            </Helmet>
            <Back />
            <h1 style={{ textAlign: "center", marginBlockStart: "0.2em" }}>Most Popular Username Terms</h1>
            <div id="term-character-option" style={{ textAlign: "center", position: "absolute", left: "20px" }}>
                <h4>Minimum Term Characters</h4>
                <input id="minCharInput" type="number" maxLength="20" value={minChar} min="1" max="10" onChange={(evt) => setMinCharFunc(parseInt(evt.target.value))} onBlur={refresh}></input>
                <Info popupPosition="top center" size="20px" text="The minimum number of characters required for a term to be shown." />
                {minCharError && (
                    <p style={{ color: "#dc3545" }}>You can only input a value from 1 to 10.</p>
                )}
            </div>

            <a id="down-on-small" className="link-button" href="#explanatory-content" style={{ float: "right", margin: "20px", right: "20px" }}><h2 style={{color:"#069"}}>How does this work?</h2></a>
            <div style={{ height: "150px" }}>
                {loading ? (
                    <div><div className="loader" ref={(el) => el && el.style.setProperty("margin-top", "0px", "important")}></div><div style={{ backgroundColor:"transparent" }} className="loader-text">Syncing with server...</div></div>
                ) : (
                    <div></div>
                )}
            </div>

            {!loading && (
                <ResetCountdown />
            )}

            <div style={{ padding: "20px" }}>
                <table className={hide}>
                    <tbody>
                        <tr>
                            <th>Count today</th>
                            <th>Term</th>
                            <th>Most recent user</th>
                        </tr>
                        {popularTermsData.map((termData, index) => (
                            <tr key={index}>
                                <td><span style={{ marginRight: "10px", width: "50px", display: "inline-block", backgroundColor:"transparent" }}>{index + 1}.</span><span style={{ backgroundColor:"transparent" }}>{termData.count}</span></td>
                                <td><span style={{ backgroundColor:"transparent" }}>{termData.term}</span></td>
                                <td>{termData.mostRecentUsername}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!loading && (
                <a className="link-button" href="#top" style={{ textAlign: "center" }}><h3>To top ^</h3></a>
            )}

            <section id="explanatory-content">
                <HowDoesThisWorkPopular />
            </section>
        </div>
    );
}

export default Popular;